





































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { Notify } from "quasar";
import Buscador from "@/components/Common/Buscador.vue";
@Component({
  components: {
    Buscador
  }
})
export default class HeaderComponent extends Vue {
  private drawer: boolean = false;
  private miniState: boolean = true;
  private logged: boolean = false;
  private isFloat: boolean = false;
  private left = false;

  get token() {
    return this.$store.state.token;
  }

  @Watch("$store.state.user")
  private userWatch(newValue: any) {
    if (newValue && newValue.id && newValue.id != "") {
      this.logged = true;
    } else {
      this.logged = false;
    }
  }
  @Watch("$store.state.cDisabledF")
  private flotanteWatch(newValue: any) {
    if (newValue) {
      this.isFloat = true;
    } else {
      this.isFloat = false;
    }
  }
  @Watch("$store.state.token.auth_token")
  private tokenWatch(newValue: any) {
    if (this.$store.state.token.auth_token) {
      this.$axios.defaults.headers.common.Authorization = `${this.token.auth_token}`;
      this.$axios("user")
        .then(res => {
          this.$store.dispatch("GET_USER", res.data).then(() => {
            this.$i18n.locale = this.$store.state.user.lenguage;
            this.logged = true;
          });
          this.$q.loading.show({
            delay: 100 // ms
          });
          this.$axios("vecinos/list")
            .then(list => {
              this.$store.dispatch("GET_PERMIT", list.data);
              this.logged = true;
              this.$q.loading.hide();
            })
            .catch(error => {
              this.$store.dispatch("GET_PERMIT", []);
              this.$q.loading.hide();
            });
          this.$store.commit("LOADING", false);
        })
        .catch();
    } else {
      this.$store.commit("LOADING", false);
    }
    if (
      this.$store.state.user &&
      this.$store.state.user.id &&
      this.$store.state.user.id != ""
    ) {
      this.logged = true;
    } else {
      this.logged = false;
    }
    if (this.$store.state.cDisabledF) {
      this.isFloat = true;
    } else {
      this.isFloat = false;
    }
  }

  private mounted() {
    if (this.$store.state.token.auth_token) {
      this.$axios.defaults.headers.common.Authorization = `${this.token.auth_token}`;
      this.$axios("user")
        .then(res => {
          this.$store.dispatch("GET_USER", res.data).then(() => {
            this.$i18n.locale = this.$store.state.user.lenguage;
            this.logged = true;
          });
          this.$q.loading.show({
            delay: 100 // ms
          });
          this.$axios("vecinos/list")
            .then(list => {
              this.$store.dispatch("GET_PERMIT", list.data);
              this.logged = true;
              this.$q.loading.hide();
            })
            .catch(error => {
              this.$store.dispatch("GET_PERMIT", []);
              this.$q.loading.hide();
            });
          this.$store.commit("LOADING", false);
        })
        .catch();
    } else {
      this.$store.commit("LOADING", false);
    }
    if (
      this.$store.state.user &&
      this.$store.state.user.id &&
      this.$store.state.user.id != ""
    ) {
      this.logged = true;
    } else {
      this.logged = false;
    }
    if (this.$store.state.cDisabledF) {
      this.isFloat = true;
    } else {
      this.isFloat = false;
    }
  }

  private misDatos() {
    if (this.$router.currentRoute.name == "perfil") {
      this.$router.go(0);
    } else {
      this.$router.push({ name: "perfil" });
    }
  }

  private editar() {
    if (this.$router.currentRoute.name == "editar") {
      this.$router.go(0);
    } else {
      this.$router.push({ name: "editar" });
    }
  }

  private configuracion() {
    if (this.$router.currentRoute.name == "configuracion") {
      this.$router.go(0);
    } else {
      this.$router.push({ name: "configuracion" });
    }
  }

  private verSolicitudes() {
    if (this.$router.currentRoute.name == "ApplicationsList") {
      this.$router.go(0);
    } else {
      this.$router.push({ name: "ApplicationsList" });
    }
  }

  private crearSolicitud() {
    if (this.$router.currentRoute.name == "solicitud") {
      this.$router.go(0);
    } else {
      this.$router.push({ name: "solicitud" });
    }
  }

  private logout() {
    this.logged = false;
    this.$store.dispatch("LOGOUT");
    if (this.$router.currentRoute.name == "login") {
      this.$router.go(0);
    } else {
      this.$router.push({ name: "login" });
    }
  }

  private lenguage(value) {
    this.$store.commit("SET_LENGUAGE", value);
    this.$i18n.locale = value;
  }

  private updateData(value) {
    this.$axios
      .put("user/idioma", {
        lang: value
      })
      .then(() => {
        this.$store.commit("SET_LENGUAGE", value);
        this.$i18n.locale = value;
      })
      .catch(() => {
        this.$store.commit("SET_LENGUAGE", value);
        this.$i18n.locale = value;
      })
      .finally(() => {
        this.$swal({
          icon: "success",
          title: this.$t("notifications.success.title").toString(),
          text: this.$t("notifications.success.UPDATE_DATA").toString(),
          confirmButtonText: this.$t("buttons.accept").toString()
        });
      });
  }

  private ruta() {
    this.$router.push({ path: "/" });
  }

  private routerGo(value: string) {
    if (value && value != "") {
      if (this.$router.currentRoute.name == value) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: value });
      }
    }
  }
}
