var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('q-header',{staticClass:"bg-barra text-black",attrs:{"elevated":""}},[_c('q-toolbar',{staticClass:"q-my-sm"},[_c('q-btn',{staticClass:"md-hide lg-hide xl-hide",staticStyle:{"color":"#fff"},attrs:{"dense":"","flat":"","round":"","icon":"menu"},on:{"click":function($event){_vm.left = !_vm.left}}}),_c('div',{staticStyle:{"height":"55px","margin-left":"8px"}},[_c('img',{staticClass:"img-fluid cursor-pointer",staticStyle:{"width":"auto !important"},attrs:{"src":require("../../assets/logos/logo_verde_celeste.png")},on:{"click":function($event){return _vm.ruta()}}})]),_c('q-space'),_c('div',{staticClass:"xs-hide sm-hide col-xs-12 col-sm-2 col-md-1 col-lg-1 col-xl-1 q-mx-sm row justify-end"},[_c('q-btn',{staticClass:"full-width q-my-sm",staticStyle:{"color":"#ffffff"},attrs:{"flat":"","label":"Inicio"},on:{"click":function($event){return _vm.ruta()}}})],1),_c('div',{staticClass:"xs-hide sm-hide col-xs-12 col-sm-2 col-md-1 col-lg-1 col-xl-1 q-mx-sm row justify-end"},[_c('q-btn',{staticClass:"full-width q-my-sm",staticStyle:{"color":"#ffffff"},attrs:{"flat":"","label":"Beneficios"},on:{"click":function($event){return _vm.routerGo('Convenios')}}})],1),_c('div',{staticClass:"xs-hide sm-hide col-xs-12 col-sm-2 col-md-1 col-lg-1 col-xl-1 q-mx-sm row justify-end"},[(
            !(
              _vm.logged ||
              (this.$store.state.user.id && this.$store.state.user.id != '')
            )
          )?_c('q-btn',{staticClass:"full-width q-my-sm",staticStyle:{"color":"#ffffff"},attrs:{"flat":"","label":"Obtener Tarjeta"},on:{"click":function($event){return _vm.routerGo('login')}}}):_vm._e(),(
            _vm.logged ||
              (this.$store.state.user.id && this.$store.state.user.id != '')
          )?_c('q-btn',{staticClass:"full-width q-my-sm",staticStyle:{"color":"#ffffff"},attrs:{"flat":"","label":"Obtener Tarjeta"},on:{"click":function($event){return _vm.verSolicitudes()}}}):_vm._e()],1),_c('div',{staticClass:"xs-hide sm-hide col-xs-12 col-sm-2 col-md-1 col-lg-1 col-xl-1 q-mx-sm row justify-end"},[_c('q-btn',{staticClass:"full-width q-my-sm",staticStyle:{"color":"#ffffff"},attrs:{"flat":"","label":"Revisar Estado"},on:{"click":function($event){return _vm.routerGo('Validar')}}})],1),_c('div',{staticClass:"xs-hide sm-hide col-xs-12 col-sm-2 col-md-1 col-lg-1 col-xl-1 q-mx-sm row justify-end"},[_c('q-btn',{staticClass:"full-width q-my-sm",staticStyle:{"color":"#ffffff"},attrs:{"flat":"","label":"Contacto"},on:{"click":function($event){return _vm.routerGo('Contacto')}}})],1),_c('div',{staticClass:"row"},[_c('q-btn',{attrs:{"flat":""}},[_c('q-icon',{staticClass:"cursor-pointer",attrs:{"name":"search","color":"white","size":"sm"},on:{"click":function($event){return _vm.$store.commit('SET_MODAL_BUSCADOR', true)}}})],1)],1),_c('q-btn-dropdown',{staticClass:"icon-login",attrs:{"flat":"","icon":"account_circle"}},[_c('q-list',{staticClass:"card-tamaño"},[(
              !(
                _vm.logged ||
                (this.$store.state.user.id && this.$store.state.user.id != '')
              )
            )?_c('q-item',{staticClass:"cursor-pointer"},[_c('q-item-section',{attrs:{"avatar":""},on:{"click":function($event){return _vm.routerGo('login')}}},[_c('q-avatar',{attrs:{"icon":"account_circle"}})],1),_c('q-item-section',{on:{"click":function($event){return _vm.routerGo('login')}}},[_c('q-item-label',[_vm._v("Iniciar Sesión")])],1)],1):_vm._e(),(
              !(
                _vm.logged ||
                (this.$store.state.user.id && this.$store.state.user.id != '')
              )
            )?_c('q-item',{staticClass:"cursor-pointer"},[_c('q-item-section',{attrs:{"avatar":""},on:{"click":function($event){return _vm.routerGo('signup')}}},[_c('q-avatar',{attrs:{"icon":"article"}})],1),_c('q-item-section',{on:{"click":function($event){return _vm.routerGo('signup')}}},[_c('q-item-label',[_vm._v("Registro")])],1)],1):_vm._e(),(
              _vm.logged ||
                (this.$store.state.user.id && this.$store.state.user.id != '')
            )?_c('q-item',{staticClass:"cursor-pointer"},[_c('q-item-section',{attrs:{"avatar":""},on:{"click":function($event){return _vm.verSolicitudes()}}},[_c('q-avatar',{attrs:{"icon":"fact_check"}})],1),_c('q-item-section',{on:{"click":function($event){return _vm.verSolicitudes()}}},[_c('q-item-label',[_vm._v(_vm._s(_vm.$t("menu.navigation.applications.list")))])],1)],1):_vm._e(),(
              (_vm.logged ||
                (this.$store.state.user.id &&
                  this.$store.state.user.id != '')) &&
                !this.isFloat
            )?_c('q-item',{staticClass:"cursor-pointer"},[_c('q-item-section',{attrs:{"avatar":""},on:{"click":function($event){return _vm.crearSolicitud()}}},[_c('q-avatar',{attrs:{"icon":"library_add"}})],1),_c('q-item-section',{on:{"click":function($event){return _vm.crearSolicitud()}}},[_c('q-item-label',[_vm._v(_vm._s(_vm.$t("menu.navigation.applications.new")))])],1)],1):_vm._e(),(
              _vm.logged ||
                (this.$store.state.user.id && this.$store.state.user.id != '')
            )?_c('q-item',{staticClass:"cursor-pointer"},[_c('q-item-section',{attrs:{"avatar":""},on:{"click":function($event){return _vm.editar()}}},[_c('q-avatar',{attrs:{"icon":"create"}})],1),_c('q-item-section',{on:{"click":function($event){return _vm.editar()}}},[_c('q-item-label',[_vm._v(_vm._s(_vm.$t("menu.navigation.profile.edit")))])],1)],1):_vm._e(),(
              _vm.logged ||
                (this.$store.state.user.id && this.$store.state.user.id != '')
            )?_c('q-item',{staticClass:"cursor-pointer"},[_c('q-item-section',{attrs:{"avatar":""},on:{"click":function($event){return _vm.configuracion()}}},[_c('q-avatar',{attrs:{"icon":"settings"}})],1),_c('q-item-section',{on:{"click":function($event){return _vm.configuracion()}}},[_c('q-item-label',[_vm._v(_vm._s(_vm.$t("menu.navigation.profile.settings")))])],1)],1):_vm._e(),(
              _vm.logged ||
                (this.$store.state.user.id && this.$store.state.user.id != '')
            )?_c('q-item',{staticClass:"cursor-pointer"},[_c('q-item-section',{attrs:{"avatar":""},on:{"click":function($event){return _vm.logout()}}},[_c('q-avatar',{attrs:{"icon":"highlight_off"}})],1),_c('q-item-section',{on:{"click":function($event){return _vm.logout()}}},[_c('q-item-label',[_vm._v(_vm._s(_vm.$t("buttons.logout")))])],1)],1):_vm._e()],1)],1)],1)],1),_c('q-drawer',{attrs:{"side":"left","show-if-above":"","behavior":"mobile","overlay":"","content-style":{ backgroundColor: '#5a2ea8' }},model:{value:(_vm.left),callback:function ($$v) {_vm.left=$$v},expression:"left"}},[_c('q-scroll-area',{staticStyle:{"height":"calc(100% - 150px)","margin-top":"100px","background-color":"#5a2ea8 !important"}},[_c('div',{staticClass:"row q-mx-lg"},[_c('div',{staticClass:"col-12 row justify-between cursor-pointer q-mt-xs items-center"},[_c('q-list',{staticClass:"rounded-borders full-width"},[_c('q-item',{directives:[{name:"close-popup",rawName:"v-close-popup"}],staticClass:"sidebar",attrs:{"clickable":""},on:{"click":function($event){return _vm.ruta()}}},[_c('q-item-section',[_c('q-item-label',{staticClass:"text-white"},[_vm._v(" Inicio ")])],1)],1),_c('q-item',{directives:[{name:"close-popup",rawName:"v-close-popup"}],staticClass:"sidebar",attrs:{"clickable":""},on:{"click":function($event){return _vm.routerGo('Convenios')}}},[_c('q-item-section',[_c('q-item-label',{staticClass:"text-white"},[_vm._v(" Beneficios ")])],1)],1),(
                !(
                  _vm.logged ||
                  (this.$store.state.user.id &&
                    this.$store.state.user.id != '')
                )
              )?_c('q-item',{directives:[{name:"close-popup",rawName:"v-close-popup"}],staticClass:"sidebar",attrs:{"clickable":""},on:{"click":function($event){return _vm.routerGo('login')}}},[_c('q-item-section',[_c('q-item-label',{staticClass:"text-white"},[_vm._v(" Obtener Tarjeta ")])],1)],1):_vm._e(),(
                _vm.logged ||
                  (this.$store.state.user.id &&
                    this.$store.state.user.id != '')
              )?_c('q-item',{directives:[{name:"close-popup",rawName:"v-close-popup"}],staticClass:"sidebar",attrs:{"clickable":""},on:{"click":function($event){return _vm.verSolicitudes()}}},[_c('q-item-section',[_c('q-item-label',{staticClass:"text-white"},[_vm._v(" Obtener Tarjeta ")])],1)],1):_vm._e(),_c('q-item',{directives:[{name:"close-popup",rawName:"v-close-popup"}],staticClass:"sidebar",attrs:{"clickable":""},on:{"click":function($event){return _vm.routerGo('Validar')}}},[_c('q-item-section',[_c('q-item-label',{staticClass:"text-white"},[_vm._v(" Revisar Estado ")])],1)],1),_c('q-item',{directives:[{name:"close-popup",rawName:"v-close-popup"}],staticClass:"sidebar",attrs:{"clickable":""},on:{"click":function($event){return _vm.routerGo('Contacto')}}},[_c('q-item-section',[_c('q-item-label',{staticClass:"text-white"},[_vm._v(" Contacto ")])],1)],1)],1)],1)])]),_c('div',{staticClass:"row col-12"},[_c('div',{staticClass:"row justify-center col-3"},[_c('div',{staticClass:"absolute-top q-pa-sm",staticStyle:{"width":"50%","height":"auto !important","margin-left":"75px"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("../../assets/logos/logo_verde_celeste.png")}})])])])],1),_c('Buscador')],1)}
var staticRenderFns = []

export { render, staticRenderFns }